/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
html {
  --alertFontSize: 14px;
  --alertBorderRadius: 10px;
  --alertPadding: 10px 14px;

  //types
  --alertSuccessBackground: rgba(245, 255, 250, .95);
  --alertSuccessColor: #147447;
  --alertSuccessBorder: #caf3da;

  --alertWarningBackground: rgba(255, 251, 245, .95);
  --alertWarningColor: #a45a11;
  --alertWarningBorder: #f3dfca;

  --alertErrorBackground: rgba(255, 243, 243,.95);
  --alertErrorColor: #E23535;
  --alertErrorBorder: #eccccc;
}

body {
  font-family: "Josefin Sans", sans-serif;
}
//Arrow input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//Arrow input number
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  width: auto !important;
  max-height: 100%;
}

.alert {
  border-width: 0 0 var(--border-width);
  border-radius: 0;
  margin: 0;

  .btn-close, .btn-close:focus {
    box-shadow: none;
    outline: none;
  }

  &:last-of-type {
    border-bottom-width: 2px;
  }

  &.alert-primary {
    background-color: var(--alert-primary-bg);
    border-color: var(--alert-primary-border-color);
    color: var(--alert-primary-color);
  }
  &.alert-secondary {
    background-color: var(--alert-secondary-bg);
    border-color: var(--alert-secondary-border-color);
    color: var(--alert-secondary-color);
  }
  &.alert-success {
    background-color: var(--alert-success-bg);
    border-color: var(--alert-success-border-color);
    color: var(--alert-success-color);
  }
  &.alert-info {
    background-color: var(--alert-info-bg);
    border-color: var(--alert-info-border-color);
    color: var(--alert-info-color);
  }
  &.alert-warning {
    background-color: var(--alert-warning-bg);
    border-color: var(--alert-warning-border-color);
    color: var(--alert-warning-color);
  }
  &.alert-danger {
    background-color: var(--alert-danger-bg);
    border-color: var(--alert-danger-border-color);
    color: var(--alert-danger-color);
  }
  &.alert-light {
    background-color: var(--alert-light-bg);
    border-color: var(--alert-light-border-color);
    color: var(--alert-light-color);
  }
  &.alert-dark {
    background-color: var(--alert-dark-bg);
    border-color: var(--alert-dark-border-color);
    color: var(--alert-dark-color);
  }
}
mk-alert-center {
  width: 100%;

  & > mk-container {
    align-items: center;
  }
}

// Global css classes
.hide{
  display:none;
}

// Compact layout override
compact-layout {
  fuse-vertical-navigation.fuse-vertical-navigation-appearance-compact .fuse-vertical-navigation-wrapper,
  fuse-vertical-navigation.fuse-vertical-navigation-appearance-compact fuse-vertical-navigation-aside-item {
    background-color: white;
  }
}

// Taiga UI - tabs
tui-tabs {
  button {
    &._active {
      border-bottom: 2px solid var(--cl-green);
      color: var(--cl-dark-200) !important;
    }
  }
}

// Material - bottom sheet
.cdk-overlay-container mat-bottom-sheet-container {
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  padding: 0;
  min-width: 45vw;
}

.cdk-overlay-container {
  z-index: 1000;
}

.mat-dialog-container {
  z-index: 1001;
}

// Material - multiple select
.optionDetailContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .titleOption {
    font-weight: 500;
  }

  p {
    font-size: 12px;
    color: var(--cl-dark-400);
    line-height: 15px;
  }
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

.mat-calendar-body-selected {
  background-color: var(--cl-green) !important;
  color: white !important;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.ngx-mat-timepicker .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-form-field {
  width: 80px !important;
  max-width:150px !important;
  margin-top : 20px !important;
}

.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td.ngx-mat-timepicker-spacer {
  width: 30px !important;
}


.fc-timegrid-event .fc-event-time {
  color: #1AC27D !important;
}
//@media (hover: hover){
//  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
//    background-color: rgba(169, 168, 0, 0.4) !important;
//  }
//}
//
//.mat-calendar-body-active {
//  .mat-calendar-body-today {
//    background-color: rgba(169, 168, 0, 0.4) !important;
//  }
//}

.mat-mdc-progress-spinner circle {
  stroke: var(--cl-dark-blue-light);
}

// override progress bar color
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: var(--cl-dark-blue-light) !important;
}
.mdc-linear-progress__bar-inner {
  border-color: var(--cl-dark-blue-light) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0 !important;
}

// override form field border color
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-focused:not(.mat-form-field-invalid) .mat-mdc-text-field-wrapper {
  --tw-border-opacity: 1 !important;
  border-color: var(--cl-dark-blue) !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select:not(.mat-mdc-select-invalid) .mat-mdc-select-arrow {
  color: var(--cl-dark-blue)!important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--cl-dark-blue)!important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
  border-radius: 15px !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--cl-dark-blue) !important;
}

.mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label {
  color: var(--cl-dark-blue) !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--cl-dark-blue) !important;
}

.mat-mdc-tab.mdc-tab--active:hover .mdc-tab-indicator__content--underline {
  border-color: var(--cl-dark-blue) !important;
}

.mat-mdc-tab-group .mat-mdc-tab-body-content {
  padding: 24px;
  padding-top: 0 !important;
}

.mdc-switch__track::before {
  background: var(--cl-white) !important;
  border: 3px solid var(--cl-golden) !important;
}

.mdc-switch__handle::before {
  background: var(--cl-golden) !important;
}

.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: var(--cl-golden) !important;
}

.mdc-switch:enabled .mdc-switch__shadow {
  box-shadow: none !important;
}

.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: var(--cl-golden)! important;
}

.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
  background: var(--cl-golden)! important;
}

.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
  background: var(--cl-golden)! important
}

.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background: var(--cl-golden) !important;
}

.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before {
  background: var(--cl-golden) !important;
}

.mdc-switch__track::after {
  background: var(--cl-golden) !important;
}

.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle, .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--cl-golden) !important;
}

.mat-mdc-list-option .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: var(--cl-golden) !important;
  background-color: var(--cl-golden) !important;
}
// CSS PLanning voyant
.fc .fc-toolbar {
  width : 100%;
  display: flex;
  justify-content: space-around;
}

.fc .fc-toolbar-title {
  font-size: 13px ! important;
  margin: 0 !important;
}

.fc .fc-button .fc-icon {
  font-size: 1em;
  vertical-align: middle;
}

.fc .fc-button {
  font-size: 12px;
}

.fc-v-event {
  background-color: #097D0E;
  border: #097D0E;
  display: block;
}

.fc-theme-standard th {
  background-color: white;
}

.fc-theme-standard td {
  background-color: rgba(2, 124, 2, 0.02) !important;
}

.fc .fc-scrollgrid-liquid {
  height: 95%;
  width : 95%;
  margin : 0 auto;
}

@media (min-width: 992px) {
  .fc .fc-scrollgrid-liquid {
    height: 50% !important;
  }
}


.fc .fc-timegrid-slot-label-cushion {
  font-size: 13px;
  text-align: center;
}

a {
  font-size : 13px;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  //top: -8%;
}

.mat-mdc-card-content {
  padding: 5px 5px 5px 5px!important;
}

.mat-mdc-card-header {
  padding: 12px 5px 0 !important;
}

.mat-mdc-card-header-text {
  width: 100% !important;
}

.mat-mdc-tab-body-content {
  overflow: scroll;
}

.mat-mdc-dialog-content {
  display:flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
