html {
  --cl-dark-blue: rgb(23, 27, 55);
  --cl-dark-blue-light: rgba(23, 27, 55, 0.16);

  --cl-white: #FFFFFF;
  --cl-dark: #000000;

  --cl-golden: #E0BE6D;
  --cl-golden-dark: #cbad70;

  --cl-grey: #CCCCCC;
  --cl-stroke-grey: #D9D9D9;

  --cl-red: #E11D48;
  --cl-green: #1AC27D;

  .title-h1 {
    font-size: 18px;
    font-weight: 600;
  }

  .title-h2 {
    font-size: 15px;
    font-weight: 600;
  }

  .paragraph-text{
    font-size: 13px;
  }

  .gold-button{
    background-color: var(--cl-golden);
    color: var(--cl-white);
    border: none;
    border-radius: 20px;
    font-size: 15px;
    width :90%;
    padding : 5px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
      background-color: var(--cl-golden-dark);
    }
  }

  .gold-empty-button{
    background-color: var(--cl-white);
    color: var(--cl-golden);
    border: 1px solid var(--cl-golden);
    border-radius: 20px;
    font-size: 15px;
    width :80%;
    padding : 5px;
    cursor: pointer;
  }

  .dark-empty-button{
    background-color: var(--cl-dark-blue);
    color: var(--cl-white);
    border: 1px solid var(--cl-dark-blue);
    border-radius: 20px;
    font-size: 14px;
    padding : 5px;
    cursor: pointer;
  }

  .red-empty-button{
    background-color: var(--cl-white);
    color: var(--cl-red);
    border: 1px solid var(--cl-red);
    border-radius: 20px;
    font-size: 15px;
    width :80%;
    padding : 5px;
    cursor: pointer;
  }

  .disabled-button{
    background-color: var(--cl-stroke-grey);
    color: var(--cl-white);
    border: none;
    border-radius: 10px;
    font-size: 15px;
  }

}
